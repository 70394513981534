<template>
	<div>
		<p>Beklager, ingen gevinst denne gang.</p>
		<p>Kjøp en til så kanskje du vinner!😉</p>
	</div>
</template>

<script>
export default {};
</script>

<style scoped></style>
