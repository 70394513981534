<template>
	<div class="flex flex-col gap-5 justify-center items-center w-full h-full">
		<p class="text-9xl">404...</p>
		<p class="text-3xl">Ops.. Denne siden fant vi ikke!</p>

		<p>{{ $route.path }}</p>
	</div>
</template>

<script>
export default {};
</script>

<style scoped></style>
