import { createWebHistory, createRouter } from 'vue-router';

import Price1 from '@/views/Price1';
import NoPrice from '@/views/NoPrice';

import PageNotFound from '@/views/PageNotFound';

const routes = [
	{ name: 'Win', path: '/winner', component: Price1 },
	{ name: 'Loss', path: '/nowin', component: NoPrice },
    { name: '404', path: '/:pathMatch(.*)*', component: PageNotFound },
];

const router = createRouter({
	history: createWebHistory(process.env.Base_URL),
	routes,
});

export default router;
