<template>
	<router-view></router-view>
</template>

<script>
export default {
	name: 'App',
};
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap');

#app {
	position: absolute;
	width: 100%;
	height: 100%;
	font-family: 'Roboto', sans-serif;
}

</style>
